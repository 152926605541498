import React from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Seo title="Home" />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >




    <div class="jumbotron p-4 p-md-5 text-white rounded bg-light">
          <div class="col-md-6 px-0">
            <h1 class="display-4 font-italic">Hama Beads Patterns Step by Step</h1>
            <p class="lead my-3">Hama bead creations can range from simple and abstract to intricate and detailed.</p>
            <p class="lead mb-0"><Link to="/patterns/" class="text-white font-weight-bold">View Patterns</Link></p>
          </div>
      </div>
      
      <main role="main" class="mt-4 container">
        <div class="row">
          <div class="col-md-8 blog-main">
            <h3 class="pb-4 mb-4 font-italic border-bottom">
            Hama beads online store
            </h3>
      
            <div class="blog-post">
              <h2 class="blog-post-title">Welcome</h2>
              <hr />
              <p>Hama beads, also known as Perler beads, are small, colorful plastic beads that can be arranged and fused together to create a wide variety of designs and patterns. These beads are popular among children and adults alike for their versatility and ease of use, making them a great craft activity for all ages.</p>
              <p>Hama bead creations can range from simple and abstract to intricate and detailed. Some popular ideas for hama bead projects include creating jewelry, keychains, coasters, and even small figurines. The possibilities are endless, and the only limit is your imagination.</p>
              <p>To get started with hama beads, you will need a few basic supplies. These include hama beads in the colors of your choice, a pegboard (also called a hama board), ironing paper, and an iron. You can find all of these supplies at a craft store or online.</p>
              <p>To begin your hama bead project, simply place the beads onto the pegboard according to your desired design. The pegboard has small pegs that the beads can be placed onto, allowing you to easily create a variety of shapes and patterns. Once you have completed your design, cover it with ironing paper and carefully iron it on a low heat setting. The heat from the iron will melt the beads together, forming a solid piece.</p>
              <p>Hama beads are a fun and creative activity that can be enjoyed by people of all ages. Whether you are a seasoned hama bead artist or just starting out, there is always something new to discover and create with these versatile beads. So why wait? Grab some hama beads and start creating today!</p>
            </div>
      
          </div>
      
          <aside class="col-md-4 blog-sidebar">
            <div class="p-4 mb-3 bg-light rounded">
              <h4 class="font-italic">About Us</h4>
              <p class="mb-0">We are a small team of enthusiasts of Hama Beads, a craft technique that consists of joining small plastic beads together to create designs and figures. We love working with Hama Beads because it is a fun and relaxing activity that can be enjoyed by people of all ages. Plus, it's a great way to exercise creativity and patience. On our website, you will find a wide selection of Hama Beads products, as well as ideas and tutorials to inspire you to create your own creations. We hope you enjoy our passion for Hama Beads as much as we do.</p>
            </div>
      
          </aside>
      
        </div>
      
      </main>
    </article>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
